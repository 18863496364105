// extracted by mini-css-extract-plugin
export var backdrop = "retry-module--backdrop--3b057";
export var memberInfo = "retry-module--memberInfo--e7490";
export var modal = "retry-module--modal--c8da9";
export var modalContent = "retry-module--modalContent--b30e7";
export var modalDesc = "retry-module--modalDesc--41787";
export var modalExample = "retry-module--modalExample--cc3c9";
export var modalFooter = "retry-module--modalFooter--40d64";
export var modalHeader = "retry-module--modalHeader--45803";
export var modalTitle = "retry-module--modalTitle--bf5d6";
export var primaryButton = "retry-module--primaryButton--2a0ab";
export var secondaryButton = "retry-module--secondaryButton--13762";
export var studentInfo = "retry-module--studentInfo--ebb27";