import React from 'react';
import { Modal } from 'react-overlays';
import * as styles from './retry.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTriangleExclamation, faX } from '@fortawesome/free-solid-svg-icons';

const RetryPopup = ({ show, onHide, renderBackdrop, onConfirm, onCancelOrder }) => {
  return (
    <Modal className={styles.modal} show={show} onHide={onHide} renderBackdrop={renderBackdrop}>
        <div className={styles.modalContent} style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <div className='h-full flex flex-col justify-between'>

            {/* Content */}
            <div className={styles.modalDesc}>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: 'red', height: '4rem'}}/>
                    <div className='mt-4 ml-4 text-xl' style={{ marginTop: '1rem', marginLeft: '1rem'}}> 
                        <h1>PAYMENT FAILED</h1>
                        <p>Do you want to try again?</p>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className={styles.modalFooter}>
              <button className={styles.secondaryButton} onClick={() => {onHide(); onCancelOrder()}}>
                <FontAwesomeIcon icon={faX} style={{ color: 'red', marginRight: '.5rem'}}/>
                Cancel
              </button>
              <button className={styles.secondaryButton} onClick={() => {onHide(); onConfirm()}}>
                <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginRight: '.5rem'}}/>
                Confirm
              </button>
            </div>
          </div>
        </div>
    </Modal>
  );
};

export default RetryPopup;
