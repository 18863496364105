import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import * as styles from './thanks.module.scss'

import { insertOrderInformation } from '../../helpers/database'

import RetryPopup from '../../components/Retry'

export default function ThanksPage() {
  const [success, setSuccess] = useState(false);
  const [showCancel, setShowCancel] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const successParam = queryParams.get('success');
      const dataString = queryParams.get('info');
  
      setSuccess(successParam === 'true');
  
      if (successParam === 'true') {
        const serializedFormValues = JSON.parse(decodeURIComponent(dataString));
  
        const result = await insertOrderInformation(serializedFormValues, 'Completed');

        if (result.success) {
          const reference = result.reference

          const emailData = {
            ...serializedFormValues,
            reference: 'Goa' + reference,
            status: 'Completed',
          };

          // SEND EMAILS
          // to Customer
          const response = await fetch('/api/orderConfirmationEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...emailData })
            })
          const responseData = await response.json();    

          // to info@growone.africa
          const response2 = await fetch('/api/sendOrderEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...emailData })
            })
          const responseData2 = await response2.json();    
        }
      } else {
        // show popup to ask if try again or cancel
        setShowCancel(true)
      }
    };
  
    fetchData();
  }, []);

  const handleCancelOrder = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const dataString = queryParams.get('info');

    const serializedFormValues = JSON.parse(decodeURIComponent(dataString));
  
        const result = await insertOrderInformation(serializedFormValues, 'Canceled');

        if (result.success) {
          const reference = result.reference

          const emailData = {
            ...serializedFormValues,
            reference: 'Goa' + reference,
            status: 'Canceled',
          };

          // SEND EMAIL
          // to info@growone.africa
          const response2 = await fetch('/api/sendOrderEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...emailData })
            })
          const responseData2 = await response2.json();    
        }
  }

  const handleCloseModal = () => {setShowCancel(false)}
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  return (
    <main className={styles.thanksPage}>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4rem'}}>
        <h1>{success ? 'Thank you for your payment!' : 'Oops!'}</h1>

        <h2>
          {success
              ? ' Your order was placed successfully'
              : ''}
        </h2>

        <p style={{ marginTop: '1rem', fontSize: 'large'}}>
          {success
            ? <><FontAwesomeIcon icon={faEnvelope} /> An email receipt has been sent to the email address you provided</>
            : <><FontAwesomeIcon icon={faTriangleExclamation} /> It seems that something went wrong, and we were unable to process your payment. Please try again or contact us.</>
          }
        </p>

        {success ? (
          <Link style={{ marginTop: '2rem', border: '1px solid black', padding: '.5rem', textDecoration: 'none'}} to="/merchandise">Continue Shopping</Link> 
        ) : (
          <Link style={{ marginTop: '2rem', border: '1px solid black', padding: '.5rem', textDecoration: 'none'}} to="/contact">Contact Us</Link>
        )}
        
      </div>

      <RetryPopup 
        show={showCancel}
        onHide={handleCloseModal}
        renderBackdrop={renderBackdrop}
        onConfirm={() => { window.location.href = '/merchandise'; }}
        onCancelOrder={handleCancelOrder}
      />

    </main>
  )
}
